import {
  ArrowBack,
  Close,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Alert,
  Button,
  ButtonProps,
  Dialog,
  DialogContent,
  DialogProps,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  Link,
  OutlinedInput,
  OutlinedInputProps,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import * as React from "react";
import { PatternFormat } from "react-number-format";
import { useMutation, useRelayEnvironment } from "react-relay";
import { fetchQuery, graphql } from "relay-runtime";
import { ConfidentialityConsent } from "../common/ConfidentialityConsent";
import { MuiPhone } from "../common/MuiPhone";
import { LoginMethod, useAuth } from "../core";
import { LoginDialogSendCodeToEmailMutation } from "../queries/LoginDialogSendCodeToEmailMutation.graphql";

const sendCodeToEmailMutation = graphql`
  mutation LoginDialogSendCodeToEmailMutation($username: String!) {
    resetPassword(username: $username, password: null, code: null) {
      codeIsSent
    }
  }
`;

function LoginButton(props: ButtonProps): JSX.Element {
  return (
    <Button variant="outlined" size="large" fullWidth {...props}>
      <span style={{ flexGrow: 1, textAlign: "center" }}>{props.children}</span>
    </Button>
  );
}

type LoginMethodButtonProps = Omit<ButtonProps, "children"> & {
  method: LoginMethod;
  icon: React.ReactNode;
};

function LoginMethodButton(props: LoginMethodButtonProps): JSX.Element {
  const { sx, method, icon, ...other } = props;

  return (
    <LoginButton
      sx={{ textTransform: "none", ...sx }}
      href={`/api/auth/${method.toLowerCase()}`}
      startIcon={icon}
      data-method={method}
      {...other}
    >
      Войти через {method}
    </LoginButton>
  );
}

function LoginInput(props: TextFieldProps): JSX.Element {
  const { sx, ...other } = props;
  return (
    <TextField
      sx={{ textTransform: "none", ...sx }}
      variant="outlined"
      fullWidth
      {...other}
    />
  );
}

interface PasswordInputProps extends OutlinedInputProps {
  helperText?: React.ReactNode;
}

function PasswordInput(props: PasswordInputProps): JSX.Element {
  const { sx, helperText, ...other } = props;

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <FormControl variant="outlined" fullWidth sx={sx}>
      <InputLabel htmlFor="password">Пароль</InputLabel>
      <OutlinedInput
        id="password"
        name="password"
        type={showPassword ? "text" : "password"}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
        label="Пароль"
        {...other}
      />
      <FormHelperText id="password-helper-text" error={true}>
        {helperText}
      </FormHelperText>
    </FormControl>
  );
}

const checkUserQuery = graphql`
  query LoginDialogCheckUserQuery($username: String) {
    checkUser(username: $username)
  }
`;

type LoginDialogProps = Omit<DialogProps, "children"> & {
  error?: string;
  errors?: {
    username?: string[];
    password?: string[];
    inviteCode?: string[];
    phone?: string[];
    code?: string[];
  };
};

const defaultFormInput = {
  username: "",
  password: "",
  password2: "",
  inviteCode: "",
  email: "",
  phone: "",
  code: "",
};
const defaultDialogState = "username";

function LoginDialog(props: LoginDialogProps): JSX.Element {
  const { error, errors, onClose, ...other } = props;
  const auth = useAuth();
  const [sendCodeToEmail] = useMutation<LoginDialogSendCodeToEmailMutation>(
    sendCodeToEmailMutation
  );

  // Auth through Google, Facebook, etc...
  //
  // const signIn = React.useCallback(function signIn(
  //   event: React.MouseEvent<HTMLButtonElement>
  // ) {
  //   event.preventDefault();
  //   const method = event.currentTarget.dataset.method as LoginMethod;
  //   auth.signIn({ method });
  // },
  // []);

  const [formInput, setFormInput] = React.useReducer(
    (
      state: typeof defaultFormInput,
      action: Partial<typeof defaultFormInput>
    ) => ({ ...state, ...action }),
    defaultFormInput
  );
  const [dialogState, setDialogState] = React.useState(defaultDialogState);
  const [usernameError, setUsernameError] = React.useState<string | null>(null);
  const [sendCodeToEmailError, setSendCodeToEmailError] = React.useState<
    string | null
  >(null);
  const [agreementChecked, setAgreementChecked] =
    React.useState<boolean>(false);

  const close = React.useCallback(() => {
    setFormInput(defaultFormInput);
    setDialogState(defaultDialogState);
    setUsernameError(null);
    onClose?.({}, "backdropClick");
  }, [setFormInput, setDialogState, onClose]);

  const handleClose = React.useCallback(
    (event: React.MouseEvent) => {
      if (event) event.preventDefault();
      close();
    },
    [close]
  );

  const handleInput = React.useCallback(
    (evt: React.ChangeEvent<HTMLInputElement>) => {
      const name = evt.target.name;
      const newValue = evt.target.value;
      //const target = evt.target;
      // if (name === "username") {
      //   let oldCaretPos = target.selectionStart ?? 0;
      //   if (newValue.startsWith("+7")) {
      //     newValue = newValue.substring(2);
      //     oldCaretPos -= 2;
      //   }
      //   let newCaretPos: null | number = null;
      //   let buf = "";
      //   const allowed = "0123456789";
      //   //let wasSkipped = false;
      //   for (let i = 0; i < newValue.length; ++i) {
      //     if (i >= oldCaretPos && newCaretPos === null)
      //       newCaretPos = buf.length;
      //     if (!allowed.includes(newValue[i])) {
      //       //wasSkipped = true;
      //       continue;
      //     }
      //     //if (wasSkipped) buf += "-";
      //     buf += newValue[i];
      //     //wasSkipped = false;
      //   }
      //   if (newCaretPos === null) newCaretPos = buf.length;
      //   newValue = "+7";
      //   newCaretPos += 2;
      //   if (buf.length > 0) {
      //     newValue += " (";
      //     newCaretPos += 2;
      //   }
      //   newValue += buf.substring(0, 3);
      //   if (buf.length > 3) {
      //     newValue += ") ";
      //     if (newCaretPos > 7) newCaretPos += 2;
      //   }
      //   newValue += buf.substring(3, 6);

      //   if (buf.length > 6) {
      //     newValue += "-";
      //     if (newCaretPos > 12) newCaretPos += 1;
      //   }
      //   newValue += buf.substring(6, 8);

      //   if (buf.length > 8) {
      //     newValue += "-";
      //     if (newCaretPos > 15) newCaretPos += 1;
      //   }
      //   newValue += buf.substring(8, 10);

      //   window.requestAnimationFrame(() => {
      //     target.selectionStart = newCaretPos;
      //     target.selectionEnd = newCaretPos;
      //   });
      // }
      setFormInput({ [name]: newValue });
    },
    []
  );
  const relay = useRelayEnvironment();

  const handleUsernameSubmit = React.useCallback(
    async (event: React.SyntheticEvent) => {
      event.preventDefault();
      /// Here we check if user exists and if he does, we ask for password
      /// If he doesn't, we ask for registration
      fetchQuery<{
        variables: { username: string };
        response: { checkUser: boolean | null };
      }>(relay, checkUserQuery, {
        username: formInput.username,
      })
        .toPromise()
        .then((data) => {
          const isValid = data?.checkUser !== null;
          setUsernameError(isValid ? null : "validation");
          if (isValid) {
            if (data?.checkUser) {
              setDialogState("password_transition");
            } else {
              setDialogState("registration");
            }
          }
        })
        .catch(() => setUsernameError("fail"));
    },
    [setDialogState, setUsernameError, formInput.username]
  );

  const handlePasswordSubmit = React.useCallback(
    (event: React.SyntheticEvent) => {
      event.preventDefault();
      auth
        .signIn({ type: "password", credentials: formInput })
        .catch(() => undefined);
    },
    [formInput, auth.signIn]
  );

  const handleSendCodeToEmailSubmit = React.useCallback(
    (event: React.SyntheticEvent) => {
      event.preventDefault();
      sendCodeToEmail({
        variables: {
          username: formInput.username,
        },
        onCompleted: (response, errors) => {
          if (errors && (errors.length ?? 0) > 0) {
            console.error(errors);
            const error = (errors[0] as any)?.extensions?.ltlshop.user
              .username?.[0];
            setSendCodeToEmailError(error ?? "Что-то пошло не так");
          } else if (!response?.resetPassword?.codeIsSent) {
            setSendCodeToEmailError("Что-то пошло не так");
          } else {
            setDialogState("password_reset2");
            setSendCodeToEmailError(null);
          }
        },
        onError: (error) => {
          console.error(error);
          setSendCodeToEmailError("Что-то пошло не так");
        },
      });
    },
    [formInput.username]
  );

  const handlePasswordResetSubmit = React.useCallback(
    async (event: React.SyntheticEvent) => {
      event.preventDefault();
      auth
        .signIn({ type: "resetPassword", resetInput: formInput })
        .catch(() => undefined);
    },
    [formInput, auth.signIn]
  );

  const handleRegistrationSubmit = React.useCallback(
    async (event: React.SyntheticEvent) => {
      event.preventDefault();
      auth
        .signIn({ type: "register", registerInput: formInput })
        .catch(() => undefined);
    },
    [formInput, auth.signIn]
  );

  const handleBack = React.useCallback(
    (event: React.SyntheticEvent) => {
      event.preventDefault();
      switch (dialogState) {
        case "registration":
          setDialogState("username");
          break;
        case "password_reset":
          setDialogState("password");
          break;
        case "password_reset2":
          setDialogState("password_reset");
          break;
        case "username":
        case "password_transition":
          close();
          break;
        case "password":
          setDialogState("username");
      }
    },
    [dialogState, setDialogState]
  );

  const passwordsAreEqual =
    !formInput.password2 ||
    formInput.password.trim() === formInput.password2.trim();

  return (
    <Dialog
      disableRestoreFocus /* See https://github.com/mui/material-ui/issues/33004 and https://stackoverflow.com/questions/75947917/how-to-focus-react-mui-textfield-when-dialog-opens */
      scroll="body"
      maxWidth="xs"
      fullWidth
      {...other}
      onClose={handleClose}
    >
      <DialogTitle sx={{ display: "flex" }}>
        {!["username", "password_transition"].includes(dialogState) && (
          <IconButton onClick={handleBack} size="large">
            <ArrowBack />
          </IconButton>
        )}
        <IconButton sx={{ ml: "auto" }} onClick={handleClose} size="large">
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent
        sx={{
          maxWidth: 430,
          "& .MuiButton-root:not(:last-of-type)": {
            marginBottom: "1rem",
          },
        }}
      >
        {/* Title */}
        <Typography sx={{ marginBottom: "1rem" }} variant="h1" align="center">
          {dialogState === "registration"
            ? "Регистрация"
            : ["password_reset", "password_reset2"].includes(dialogState)
            ? "Сброс пароля"
            : "Вход"}
        </Typography>

        {/* Content */}

        <form
          onSubmit={
            {
              username: handleUsernameSubmit,
              password: handlePasswordSubmit,
              password_transition: handlePasswordSubmit,
              password_reset: handleSendCodeToEmailSubmit,
              password_reset2: handlePasswordResetSubmit,
              registration: handleRegistrationSubmit,
            }[dialogState]
          }
        >
          {["username", "password", "password_transition"].includes(
            dialogState
          ) ? (
            <>
              <LoginInput
                autoFocus
                label="Email"
                id="username"
                name="username"
                value={formInput.username}
                onChange={handleInput}
                autoComplete="email"
                error={!!usernameError}
                helperText={
                  usernameError
                    ? usernameError == "fail"
                      ? " "
                      : "Введите корректный адрес."
                    : " "
                }
                disabled={
                  dialogState === "password_transition" ||
                  dialogState === "password"
                }
              />
              {["password", "password_transition"].includes(dialogState) && (
                <>
                  <PasswordInput
                    inputRef={(input) => {
                      if (dialogState === "password_transition") {
                        input?.focus();
                        setDialogState("password");
                      }
                    }}
                    value={formInput.password}
                    onChange={handleInput}
                    autoComplete="current-password"
                    error={!!errors?.password}
                    helperText={errors?.password ?? " "}
                  />

                  <Link
                    sx={{ mt: 1 }}
                    href="#"
                    onClick={() => setDialogState("password_reset")}
                  >
                    {" "}
                    Забыли пароль?{" "}
                  </Link>
                </>
              )}
              {/* Error message s*/}
              {!!error && (
                <Alert sx={{ mt: 1 }} severity="error">
                  {error}
                </Alert>
              )}

              <LoginButton sx={{ my: 3 }} type="submit">
                {dialogState === "username" ? "Продолжить" : "Войти"}
              </LoginButton>
            </>
          ) : dialogState === "password_reset" ? (
            <>
              <Typography
                sx={{ marginBottom: "1rem" }}
                variant="body1"
                align="center"
              >
                Введите адрес электронной почты, который вы использовали при
                регистрации. Мы отправим вам письмо со ссылкой для сброса
                пароля.
              </Typography>
              <LoginInput
                autoFocus
                label="Email"
                id="username"
                name="username"
                value={formInput.username}
                onChange={handleInput}
                autoComplete="email"
                error={!!sendCodeToEmailError}
                helperText={sendCodeToEmailError ?? " "}
              />
              {/* Error message s*/}
              {!!error && (
                <Alert sx={{ mt: 1 }} severity="error">
                  {error}
                </Alert>
              )}

              <LoginButton sx={{ my: 3 }} type="submit">
                Отправить письмо
              </LoginButton>
            </>
          ) : dialogState === "password_reset2" ? (
            <>
              <PatternFormat
                autoFocus
                label="Код"
                name="code"
                value={formInput.code}
                sx={{ mb: 2 }}
                format="######"
                mask="_"
                customInput={LoginInput}
                onChange={handleInput}
                error={!!errors?.code}
                helperText={errors?.code ?? " "}
                required
              />
              <PasswordInput
                value={formInput.password}
                onChange={handleInput}
                sx={{ my: 2 }}
                autoComplete="new-password"
                error={!!errors?.password || !passwordsAreEqual}
                helperText={errors?.password ?? " "}
                required
              />
              <LoginInput
                label="Повтор пароля"
                id="password2"
                name="password2"
                sx={{ mb: 3 }}
                type="password"
                value={formInput.password2}
                onChange={handleInput}
                autoComplete="new-password"
                error={!passwordsAreEqual}
                helperText=" "
                required
              />
              {/* Error message */}
              {error && (
                <Alert sx={{ mt: 1 }} severity="error">
                  {error}
                </Alert>
              )}
              {!error && !passwordsAreEqual && (
                <Alert sx={{ mt: 1 }} severity="warning">
                  {"Пароли различаются."}
                </Alert>
              )}
              {!error && passwordsAreEqual && (
                <Alert sx={{ mt: 1, visibility: "hidden" }}>{""}</Alert>
              )}

              <LoginButton
                disabled={!passwordsAreEqual}
                sx={{ my: 3 }}
                type="submit"
              >
                Сбросить пароль
              </LoginButton>
            </>
          ) : (
            <>
              <LoginInput
                label="Email"
                id="username"
                name="username"
                value={formInput.username}
                sx={{ display: "none" }}
              />
              <PasswordInput
                autoFocus
                value={formInput.password}
                onChange={handleInput}
                sx={{ my: 2 }}
                autoComplete="new-password"
                error={!!errors?.password || !passwordsAreEqual}
                helperText={errors?.password ?? " "}
                required
              />
              <LoginInput
                label="Повтор пароля"
                id="password2"
                name="password2"
                sx={{ mb: 3 }}
                type="password"
                value={formInput.password2}
                onChange={handleInput}
                autoComplete="new-password"
                error={!passwordsAreEqual}
                helperText=" "
                required
              />
              <MuiPhone
                label="Телефон"
                name="phone"
                value={formInput.phone}
                sx={{ mb: 2 }}
                onChange={(name, value) => setFormInput({ [name]: value })}
                error={!!errors?.phone}
                helperText={errors?.phone ?? " "}
                fullWidth
              />
              <LoginInput
                label="Чтобы получать скидки, введите код приглашения"
                id="inviteCode"
                name="inviteCode"
                type="text"
                value={formInput.inviteCode}
                onChange={handleInput}
                autoComplete="invite-code"
                error={!!errors?.inviteCode}
                helperText={errors?.inviteCode ?? " "}
              />
              {/* Error message */}
              {error && (
                <Alert sx={{ mt: 1 }} severity="error">
                  {error}
                </Alert>
              )}
              {!error && !passwordsAreEqual && (
                <Alert sx={{ mt: 1 }} severity="warning">
                  {"Пароли различаются."}
                </Alert>
              )}
              {!error && passwordsAreEqual && (
                <Alert sx={{ mt: 1, visibility: "hidden" }}>{""}</Alert>
              )}

              <ConfidentialityConsent
                agreementChecked={agreementChecked}
                setAgreementChecked={setAgreementChecked}
              />
              <LoginButton
                disabled={!passwordsAreEqual || !agreementChecked}
                sx={{ my: 3 }}
                type="submit"
              >
                Войти
              </LoginButton>
            </>
          )}
        </form>
      </DialogContent>
    </Dialog>
  );
}

export { LoginDialog };
